html, body {
  margin: 0;
  padding: 0;
}

body {
  background-color: #230b61;
  background-image: url("background-v3@blur.14f022ba.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

/*# sourceMappingURL=index.add4fc5e.css.map */
